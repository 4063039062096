import React, { Component } from 'react'
import img from '../../../assets/img/box_1.jpg';
import {get} from '../../../Api';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = { services:[],settings:{} }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let settings = await get('settings/' + lang);
        settings = settings.data.data;
        let services = await get('services/' + lang);
        services = services.data.data;
        this.setState({ settings,services,loading:false  });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let services = await get('services/' + lang);
            services = services.data.data;
            let settings = await get('settings/' + lang);
            settings = settings.data.data;
            this.setState({ settings,services,loading:false  });
        }
    }
    render() { 
      const {t} = this.props;
      const {services,settings} = this.state;
      const template = services.map((item,index)=>(
        <div key={index} className="col-md-6 col-xl-3">
          <div className="img-box">
            <div className="img-box__overlay" />
            <div className="img-box__text-layout">{item.s_title}</div><img className="img--bg" src={item.img} alt="img" />
            <div className="img-box__details">
              <h5 className="img-box__title"><Link to={"/services/"}>{item.s_title}</Link></h5>
              <div className="img-box__count">{index+1}</div>
            </div>
          </div>
        </div>
      ));
        return ( 
            <section className="section pb-0 services_2 bg--lgray">
        <div className="container bottom-70">
          <div className="row">
            <div className="col-lg-4">
              <div className="heading"><span className="heading__pre-title">{t('services')}</span>
                <h3 className="heading__title">{t('tservices')}</h3><span className="heading__layout layout--lgray">{t('services')}</span>
              </div>
            </div>
            <div className="col-lg-8 top-20 top-lg-0">
              <p className="bottom-0">{settings.s_vision}</p><Link className="button button--green d-none d-lg-inline-block top-30 top-lg-none" to="/services"><span>{t('more')}</span> 
                <svg className="icon">
                  <use xlinkHref="#arrow" />
                </svg></Link>
            </div>
          </div>
        </div>
        <div className="container container--wide">
          <div className="row offset-50">
            {template}
            
          </div>
        </div>
        <div className="container top-50 d-block d-lg-none">
          <div className="row">
            <div className="col-12 text-center"><Link className="button button--green" to="/services"><span>{t('more')}</span> 
                <svg className="icon">
                  <use xlinkHref="#arrow" />
                </svg></Link></div>
          </div>
        </div>
      </section>
         );
    }
}
 
const mapStateToProps = state =>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(Services));