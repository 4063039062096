import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class Downloads extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],covers:{} }
    }

    async componentDidMount(){
        const {lang} = this.props;
        let data = await get('/downloads/'+lang);
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data,covers });
    }
    async componentDidUpdate(preProps){
        const {lang} = this.props;
        if (preProps.lang != lang) {
            let data = await get('/downloads/'+lang);
            data = data.data.data;
            this.setState({ data });
        }
    }
    render() { 
      const {t} = this.props;
        const downloadsTemplate = this.state.data.map((item,index)=>(
             <div className="col-md-6 col-lg-4">
              <div className="downloads">
                <h4 className="downloads__title">{item.d_title}</h4><a className="downloads__icon" href="#" download="download">
                  <svg className="icon">
                    <use xlinkHref="#download" />
                  </svg></a>
                <p>{item.d_content}</p>
                <div className="downloads__details">{item.d_date}</div>
              </div>
            </div>
        ));
        return ( <div>
            <Navbar />
            <Cover title={t('downloads')} cover={this.state.covers.c_img6} />
            <section className="section pb-100">
        <div className="container">
          <div className="row bottom-50">
            <div className="col-12">
              <div className="heading heading--center"><span className="heading__pre-title">{t('downloads')}</span>
                <h3 className="heading__title">{t('idownloads')}</h3>
              </div>
            </div>
          </div>
          <div className="row offset-50">
           
            {downloadsTemplate}
            
          </div>
        </div>
      </section>
            <Footer />
        </div> );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Downloads));