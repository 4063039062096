import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel2';
import {get} from '../../../Api'
class Companies extends Component {
    constructor(props) {
        super(props);
        this.state = { clients:[],loading:true }
    }
    async componentDidMount(){
        const {lang} = this.props;
        console.log(lang);
        let clients = await get('team/en');
        clients = clients.data.data;
        console.log(clients,'my');
        this.setState({ clients,loading:false  });
    }
  
    render() { 
        const {clients} = this.state;
        const template = clients.map((item,index)=>(
            <div className="logos-slider__item"><img src={item.t_img} alt="logo" /></div>

        ))
        const {t} = this.props;
        return ( 
    <section className="section bg--lgray">
        <div className="container">
          <div className="row bottom-70">
            <div className="col-12">
              <div className="heading heading--center"><span className="heading__pre-title">{t('partners')}</span>
                <h3 className="heading__title" dangerouslySetInnerHTML={{__html:t('companies')}}></h3><span className="heading__layout layout--lgray">{t('partners')}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* logos slider start*/}
              <div className="logos-slider">
                {this.state.loading?null:<OwlCarousel>
                  {this.state.loading?null:template}
                </OwlCarousel>}
              </div>
              <div className="logos-slider__dots" />
              {/* logos slider end*/}
            </div>
          </div>
        </div>
    </section>
         );
    }
}
 
export default withTranslation()(Companies);