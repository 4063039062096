import React, { Component } from 'react'
import { connect } from 'react-redux';
import Cover from '../../Components/Cover';
import Contact from '../Home/Components/Contact';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api'
import { withTranslation } from 'react-i18next';
class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],covers:{} }
    }
    async componentDidMount (){
        const {lang} = this.props;
        let data = await get('partners/'+lang);
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data ,covers });
    }
    async componentDidUpdate (preProps){
        const {lang} = this.props;
        if(preProps.lang != lang){
            let data = await get('partners/' + lang);
            data = data.data.data;
            this.setState({
                data
            });
        }
    }
    render() { 
        const {t} = this.props;
        const template = this.state.data.map((item,index) => (
            <div className="col-lg-6">
              <div className="location-item">
                <div className="location-item__img"><img className="img--bg" src={item.img} alt="img" /></div>
                <div className="location-item__lower">
                  <h4 className="location-item__title"><a href="#">{item.p_title}</a></h4>
                  <p dangerouslySetInnerHTML={{__html:item.p_content}}></p>
                </div>
              </div>
            </div>
        ));
        return ( <div>
            <Navbar />
            <Cover title={t('partners')} cover={this.state.covers.c_img9} />
            <section className="section">
        <div className="container">
          <div className="row offset-50">
            {template}
           
          </div>
        </div>
      </section>
            <Contact />
            <Footer />
        </div> );
    }
}
 
const mapStateToProps = state =>{
    return{
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Partners));