import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import Cover from '../../Components/Cover';
import Contact from '../Home/Components/Contact';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {get,post} from '../../Api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {Formik,Form,Field} from 'formik';
import qs from 'qs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = { product:{} }
    }
     async componentDidMount(){
        const {lang} = this.props;
        let id = this.props.match.params.id;
        let product = await get('product/' + id +'/'+lang);
        product = product.data.data;
        this.setState({ product,loading:false  });
      }
      async componentDidUpdate(prevProps){
        const {lang} = this.props;
        let id = this.props.match.params.id;
        if(prevProps.lang != lang || prevProps.match.params.id != id){
          let product = await get('product/' + id +'/'+lang);
          product = product.data.data;
          this.setState({ product,loading:false  });
        }
    }
    handleSubmit = (values,{resetForm})=>{
      post('request',qs.stringify(values)).then(res =>{
        const data = res.data;
        if (data.status) {
          NotificationManager.success('Thannk You !');
          resetForm({})
        } else {
          NotificationManager.error(data.error);
        }
      });
    }
    render() { 
      const {product} = this.state;
      const {t} = this.props;
        return ( 
            <div>
                <Navbar />
                <Cover  title={product.p_title}/>
    <section className="section shop-product pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-0">
              {/* dual slider start*/}
              <div className="dual-slider">
                <div className="main-slider">
                  <OwlCarousel options={{items:1}} dir="ltr">
                    <div className="main-slider__item">
                        <div className="main-slider__img"><img className="img--contain" src={product.img} alt="first" /></div>
                    </div>
                    <div className="main-slider__item">
                        <div className="main-slider__img"><img className="img--contain" src={product.img2} alt="single" /></div>
                    </div>
                    <div className="main-slider__item">
                        <div className="main-slider__img"><img className="img--contain" src={product.img3} alt="single" /></div>
                    </div>
                    <div className="main-slider__item">
                        <div className="main-slider__img"><img className="img--contain" src={product.img4} alt="single" /></div>
                    </div>
                  </OwlCarousel>
                </div>
             
              </div>
              {/* dual slider end*/}
            </div>
            <div className="col-lg-6">
              <div className="shop-product__top">
                <h3 className="shop-product__name">{product.p_title}</h3>
                <span className="shop-product__price">Code: {product.p_code}</span>
              </div>
              <div className="shop-product__description" dangerouslySetInnerHTML={{__html:product.p_content}}>
                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-xl-9">
              <div className="tabs horizontal-tabs shop-product__tabs">
                <Tabs className="horizontal-tabs__header">
                    <TabList>
                        <Tab><a href="#horizontal-tabs__item-1"><span>{t('spec')}</span></a></Tab>
                        <Tab><a href="#horizontal-tabs__item-2"><span>{t('request')}</span></a></Tab>
                    </TabList>

                    <div className="horizontal-tabs__content">
                        <TabPanel>
                            <div className="horizontal-tabs__item" id="horizontal-tabs__item-1">
                                <p dangerouslySetInnerHTML={{__html:product.p_spec}}></p>
                                <p><img src={product.spec_img} alt="single" /></p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="horizontal-tabs__item" id="horizontal-tabs__item-2">
                        <p>{t('sdata')}</p>
                        <Formik initialValues={{
                          name:'',
                          email:'',
                          mobile:'',
                          country:'',
                          company: '',
                          address:'',
                          notes:'',
                          id:product.p_master
                        }} onSubmit={this.handleSubmit}>
                          <Form className="form comments-form">
                              <div className="row">
                            <div className="col-12">
                            <h5 className="comments-form__title">{t('request')}</h5>
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="text" name="name" placeholder={t('name')} />
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="email" name="email" placeholder={t('email')} />
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="text" name="mobile" placeholder={t('mobile')} />
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="text" name="company" placeholder={t('company')} />
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="text" name="country" placeholder={t('country')} />
                            </div>
                            <div className="col-md-6">
                            <Field className="form__field" type="text" name="address" placeholder={t('address')} />
                            </div>
                            <div className="col-12">
                            <Field className="form__field form__message" as="textarea" name="notes" placeholder={t('notes')} defaultValue={""} />
                            </div>
                            <div className="col-12">
                            <button className="button button--green" type="submit"><span>{t('send')}</span> 
                                <svg className="icon">
                                <use xlinkHref="#arrow" />
                                </svg>
                            </button>
                            </div>
                        </div>
                          </Form>
                        </Formik>
                        
                    </div>
                        </TabPanel>

                    </div>
                </Tabs>
               
                <div className="horizontal-tabs__content">
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
                <Contact />
                <Footer />

            </div>
         );
    }
}
 
const mapStateToProps = state =>{
  return {
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(Product));