import React, { Component } from 'react'
import img from '../../../assets/img/promo_3.jpg';
import img2 from '../../../assets/img/promo_video.jpg';
import OwlCarousel from 'react-owl-carousel2';
import {get} from '../../../Api';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            slides:[],
            settings:{},
            loading:true,
            open:false
         }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let slides = await get('slides/'+lang);
        slides = slides.data.data;
        let settings = await get('settings/' + lang);
        settings = settings.data.data;
        this.setState({ slides,settings,loading:false  });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let slides = await get('slides/'+lang);
            slides = slides.data.data;
            let settings = await get('settings/' + lang);
            settings = settings.data.data;
            this.setState({ slides,settings,loading:false  });

        }
    }
    render() { 
        const options = {
            items: 1,
            nav: true,
            rewind: true,
            autoplay: true
        };
        const template = this.state.slides.map((item,index)=>(
                    <div className="promo-slider__item">
                        <div className="promo-slider__layout" />
                            <picture>
                                <source srcSet={item.img} media="(min-width: 992px)" /><img className="img--bg" src={item.img} alt="img" />
                            </picture>
                            <div className="align-container">
                                <div className="align-container__item">
                                    <div className="container">
                                    <div className="row" />
                                    <div className="">
                                        <div className="promo-slider__wrapper-1"><span className="promo-slider__overlay">ELTAWHEED</span>
                                            <h2 className="promo-slider__title">{item.s_title}</h2>
                                        </div>
                                        <div className="promo-slider__wrapper-2">
                                        <p className="promo-slider__subtitle">
                                           {item.s_content}
                                        </p>
                                        </div>
                                        <div className="promo-slider__wrapper-3"><Link className="button button--promo" to={item.s_link}>
                                            <span>{item.s_link_title}</span> 
                                            <svg className="icon">
                                                <use xlinkHref="#arrow" />
                                            </svg></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        ));
        return ( <div>
            {console.log(this.state.settings.slider_video_id,'video')}
            <ModalVideo channel='youtube' autoplay isOpen={this.state.open} videoId={this.state.settings.slider_video_id} onClose={() => this.setState({ open:false  })} />

            <div className="promo promo--f3">

                {this.state.loading?'Loading':<OwlCarousel options={options} dir="ltr">
                    {template}
                    
                </OwlCarousel>}

                <div className="promo-pannel"><a className="js-video promo-pannel__video"  onClick={()=>this.setState({ open:true  })}><img className="img--bg" src={img2} alt="img" /><span className="icon-holder">
                    <svg className="icon">
                        <use xlinkHref="#play" />
                    </svg></span></a>
                <div className="promo-pannel__inner">
                    <div className="row">
                    <div className="col-12">
                        <h6 className="promo-pannel__title">Technical Support</h6><a className="promo-pannel__link" href={"mailto:"+this.state.settings.email}>
                        <svg className="icon">
                            <use xlinkHref="#mail" />
                        </svg><span>{this.state.settings.email}</span></a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="promo__lower">
                <div className="container">
                    <div className="row">
                    <div className="col-12 d-flex align-items-start justify-content-sm-end">
                        <div className="promo-slider__nav" />
                        <div className="promo-slider__count" />
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div> );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(Slider);