import axios from 'axios';
import qs from 'qs';

const base_url = 'http://api.eltawheed-eg.com/public/home/';
// const base_url = 'http://api.eltawheed-eg.com/public/';
export function get(url) {
    return axios.get(base_url+url);
}

export function post(url,values){
    return axios.post(base_url+url,values,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export function posti(url, values) {
    return axios.post(base_url + url, values, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}