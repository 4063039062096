import React, { Component } from 'react'
import img from '../assets/img/about_bg.jpg';
class Cover extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
      const {cover} = this.props;
        return ( 
    <section className="hero-block">
        <picture>
          <source srcSet={cover != ''?cover:img} media="(min-width: 992px)" /><img className="img--bg" src={cover != ''?cover:img} alt="img" />
        </picture>
        <div className="hero-block__layout" />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="align-container">
                <div className="align-container__item"><span className="hero-block__overlay">ELTAWHEED</span>
                  <h1 className="hero-block__title">{this.props.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
         );
    }
}
 
export default Cover;