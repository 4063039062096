import React, { Component } from 'react'
import img1 from '../../../assets/img/bl_1.jpg';
import img2 from '../../../assets/img/article_2.jpg';
import img3 from '../../../assets/img/article_3.jpg';
import {get} from '../../../Api';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = { blog:[],loading:true }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let blog = await get('blog/' + lang);
        blog = blog.data.data;
        this.setState({ blog,loading:false  });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let blog = await get('blog/' + lang);
            blog = blog.data.data;
            this.setState({ blog,loading:false  });
        }
    }
    render() { 
      const {t} = this.props;
        const {blog,loading} = this.state;
        const template = blog.map((item,index)=>(
          <div key={index} className="articles-slider__item">
              <div className="article">
              <div className="article__img"><img className="img--bg" src={item.img} alt="img" /></div>
              <div className="article__lower">
                  <h6 className="article__title"><Link to={"/article/"+item.b_master}>{item.b_title}</Link></h6>
                  <p className="article__text">{item.b_content}...</p>
                  <div className="article__details"><span>{item.date}</span></div>
              </div>
              </div>
          </div>
        ));
        return ( <section className="section front-blog">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 d-flex flex-column align-items-start">
              <div className="heading"><span className="heading__pre-title">{t('blog')}</span>
        <h3 className="heading__title" dangerouslySetInnerHTML={{__html:t("latestb")}}></h3><span className="heading__layout layout--lgray">{t('blog')}</span>
              </div><Link className="d-none d-xl-inline-block button button--green" to="/blog"><span>{t('more')}</span> 
                <svg className="icon">
                  <use xlinkHref="#arrow" />
                </svg></Link>
              <div className="articles-slider__nav" />
            </div>
            <div className="col-xl-8">
              <div className="articles-slider-wrapper">
                <div className="articles-slider">
                <OwlCarousel options={{margin:30}} dir="ltr">
                    {loading?'':template}
                </OwlCarousel>
                </div>
              </div>
              <div className="row top-50 d-block d-xl-none">
                <div className="col-12 text-center"><a className="button button--green" href="#"><span>{t('more')}</span> 
                    <svg className="icon">
                      <use xlinkHref="#arrow" />
                    </svg></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> );
    }
}
 
const mapStateToProps = state =>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(Blog));