import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api/index';
class Videos extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],covers:{} }
    }
    async componentDidMount(){
        const {lang,i18n} = this.props;
        let data = await get('media/1');
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data,loading:false,covers  });
    }
    async componentDidUpdate(prevProps){
        if(prevProps.lang != this.props.lang){
            const {lang} = this.props;
            let data = await get('media/1');
            data = data.data.data;
            this.setState({ data,loading:false  });

        }
    }
    render() { 
        const {data} = this.state;
        const template = data.map((item,index)=>(
            <div key={index} className="js-gallery__item category_1 col-md-6 col-lg-4">
              <a className="gallery__item" href={item.m_img} data-fancybox="gallery"><span className="overlay" />
                <img className="img--bg" src={item.m_img} alt="img" />
                <span className="gallery__description">{item.m_title}</span>
              </a>
            </div>
        ));
        return ( <div>
            <Navbar />
            <Cover title="Videos" cover={this.state.covers.c_img4}/>
            <section className="section">
        <div className="container">
          <div className="js-gallery gallery gallery--grid row no-gutters">
            {template}
          </div>
         
        </div>
      </section>
            <Footer />
        </div> );
    }
}
 
export default Videos;