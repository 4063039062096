import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import img from '../../assets/img/contacts-map.png';
import { withTranslation } from 'react-i18next';
import {get,post} from '../../Api';
import {Formik,Form,Field} from 'formik';
import qs from 'qs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { data:{},covers:{} }
    }
    async componentDidMount(){
      let data = await get('settings/en');
      data = data.data.data
      let covers = await get('covers');
      covers = covers.data.data;
      this.setState({ data ,covers});
    }
     handleSubmit = (values,{resetForm})=>{
       console.log(values)
      post('contact',qs.stringify(values)).then(res =>{
        const data = res.data;
        if (data.status) {
          NotificationManager.success('Thannk You !');
          resetForm({})
        } else {
          NotificationManager.error(data.error);
        }
      });
    }
    render() { 
      const {t} = this.props;
      const {data} = this.state;
        return ( <div>
            <Navbar />
            <Cover title={t('contact')} cover={this.state.covers.c_img10} />
            <section className="section contacts pb-0"><img className="contacts__bg" src={img} alt="map" />
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5 className="contacts__title">{t('b1')}</h5>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#pin" />
                  </svg>
                </div>
                <div className="contacts-item__details"><span>{data.address}</span></div>
              </div>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#phone" />
                  </svg>
                </div>
                <div className="contacts-item__details"><span>{data.phone}</span></div>
              </div>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#mail" />
                  </svg>
                </div>
                <div className="contacts-item__details"><a className="contacts-item__link" href={"mailto:"+data.email}>{data.email}</a></div>
              </div>
              <div dangerouslySetInnerHTML={{__html:data.map}}></div>
              <br /><br />
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#share" />
                  </svg>
                </div>
                <div className="contacts-item__details">
                  <ul className="socials socials--dark list--reset">
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                          <use xlinkHref="#facebook" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                          <use xlinkHref="#twitter" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                          <use xlinkHref="#linkedin" />
                        </svg></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 top-50 top-md-0">
              <h5 className="contacts__title">{t('b2')}</h5>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#pin" />
                  </svg>
                </div>
                <div className="contacts-item__details"><span>{data.address2}</span></div>
              </div>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#phone" />
                  </svg>
                </div>
                <div className="contacts-item__details"><span>{data.phone2}</span></div>
              </div>
              <div className="contacts-item">
                <div className="contacts-item__img">
                  <svg className="icon">
                    <use xlinkHref="#mail" />
                  </svg>
                </div>
                <div className="contacts-item__details"><a className="contacts-item__link" href={"mailto:"+data.email2}>{data.email2}</a></div>
              </div>
              <div dangerouslySetInnerHTML={{__html:data.map2}}></div>
              <br />
            </div>
          </div>
        </div>
      </section>


      <section className="section">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-lg-12">
              <Formik initialValues={{
                name:'',
                email:'',
                phone:'',
                subject:'',
                message:'',
              }}
              onSubmit={this.handleSubmit}>

                <Form className="form contact-form" id="ajax-form" action="javascript:void(0);" method="post" autoComplete="off">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="contact-form__subtitle">{t('contact')}</h5>
                    </div>
                    <div className="col-md-6">
                      <Field className="form__field" type="text" name="name" placeholder={t('name')} />
                    </div>
                    <div className="col-md-6">
                      <Field className="form__field" type="email" name="email" placeholder={t('email')} />
                    </div>
                    <div className="col-md-6">
                      <Field className="form__field" type="tel" name="phone" placeholder={t('phone')} />
                    </div>
                    <div className="col-md-6">
                      <Field className="form__field" type="text" name="subject" placeholder={t('subject')} />
                    </div>
                    <div className="col-12">
                      <Field as='textarea' className="form__field form__message message--large" name="message" placeholder={t('notes')} defaultValue={""} />
                    </div>
                    <div className="col-12">
                      <button className="button button--green" type="submit"><span>{t('send')}</span> 
                        <svg className="icon">
                          <use xlinkHref="#arrow" />
                        </svg>
                      </button>
                    </div>
                    <div className="col-12">
                      <div className="alert alert--success alert--filled">
                        <div className="alert__icon">
                          <svg className="icon">
                            <use xlinkHref="#check" />
                          </svg>
                        </div>
                        <p className="alert__text"><strong>Well done!</strong> Your form has been sent</p><span className="alert__close">
                          <svg className="icon">
                            <use xlinkHref="#close" />
                          </svg></span>
                      </div>
                      <div className="alert alert--error alert--filled">
                        <div className="alert__icon">
                          <svg className="icon">
                            <use xlinkHref="#close" />
                          </svg>
                        </div>
                        <p className="alert__text"><strong>Oh snap!</strong> Your form has not been sent</p><span className="alert__close">
                          <svg className="icon">
                            <use xlinkHref="#close" />
                          </svg></span>
                      </div>
                    </div>
                  </div>
                </Form>

              </Formik>
            </div>
          </div>
        </div>
      </section>

            <Footer/>
        </div> );
    }
}
 
export default withTranslation()(Contact);