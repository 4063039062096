import React, { Component } from 'react';
import Cover from '../../Components/Cover';
import Contact from '../Home/Components/Contact';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import FactoryItem from './Components/FactoryItem';
class Factory extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <div>
            <Navbar />
            <Cover title="Factory"/>
            <FactoryItem />
            <Contact />
            <Footer />
        </div> );
    }
}
 
export default Factory;
