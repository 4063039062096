import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import {get} from '../../../Api';
class TimeLine extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[] }
    }
    async componentDidMount(){
      const {lang} = this.props;
      let data = await get('history/'+lang);
      data = data.data.data;
      this.setState({ data });
    }
    async componentDidUpdate(prevProps){
      const {lang} = this.props;
      if(prevProps.lang != lang){
        let data = await get('history/'+lang);
        data = data.data.data;
        this.setState({ data });
      }
    }
    render() { 
      const {t} = this.props;
      const Template = this.state.data.map((item,index)=>(
        <div className="steps-slide">
          <div className="steps-slide__top">
            <div className="steps-slide__date"><span>{item.year}</span><span>{t('year')}</span></div>
          </div>
          <div className="steps-slide__lower">
            <h6 className="steps-slide__title">{item.h_title}</h6>
            <p className="bottom-0">{item.h_content}</p>
          </div>
        </div>
      ));
        return ( 
        <>
        <section className="section history bg--lgray">
          <div className="container">
            <div className="row bottom-70 align-items-end">
              <div className="col-md-7">
                <div className="heading"><span className="heading__pre-title">{t('history')}</span>
                  <h3 className="heading__title">{t('htext')}</h3>
                </div>
              </div>
              <div className="col-md-5 text-md-right top-30">
                <div className="steps-slider__nav" />
              </div>
            </div>
          </div>
          <div className="slider-holder">
            <div className="slider-holder__wrapper">
              <div className="steps-slider history--slider">
                <OwlCarousel dir="ltr">

                  {Template}

                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        </>
         );
    }
}
 
const mapStateToProps = state =>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(TimeLine));