import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get,post} from '../../Api';
import img from '../../assets/img/author_1.png';
import { connect } from 'react-redux';
import ContactBox from '../../Components/ContactBox';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {Formik,Form,Field} from 'formik';
import qs from 'qs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
class Article extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            article:[],
            latest:[],
            archive:[],
            comments:[],
            comments_num:0,
            loading:true
         }
    }
    async componentDidMount (){
        const id = this.props.match.params.id;
        const {lang} = this.props;
        let data = await get('article/'+id+'/'+lang);
        data = data.data;
        let article = data.data;
        let latest = data.latest;
        let archive = data.archive;
        let comments = data.comments;
        let comments_num = data.comments_num;
        this.setState({ 
            article,
            latest,
            archive,
            comments,
            comments_num,
            loading:false
        });
    }
    async componentDidUpdate (prevProps){
        const id = this.props.match.params.id;
        const {lang} = this.props;
        if(prevProps.lang != lang || prevProps.match.params.id != id){
            let data = await get('article/' + id + '/' + lang);
            data = data.data;
            let article = data.data;
            let latest = data.latest;
            let archive = data.archive;
            let comments = data.comments;
            let comments_num = data.comments_num;
            this.setState({
                article,
                latest,
                archive,
                comments,
                comments_num,
                loading:false
            });
        }
    }

     handleSubmit = (values,{resetForm})=>{
       console.log(values)
      post('comment',qs.stringify(values)).then(res =>{
        const data = res.data;
        if (data.status) {
          NotificationManager.success('Thannk You !');
          resetForm({})
        } else {
          NotificationManager.error(data.error);
        }
      });
    }
    render() { 
        const {
            article,
            latest,
            archive,
            comments,
            comments_num
        } = this.state;
        const {t} = this.props;

        const LatestTemplate = latest.map((item,index)=>(
            <div className="latest-item">
                <div className="latest-item__img"><img className="img--bg" src={item.img} alt="img" /></div>
                <div className="latest-item__details">
                    <h6 className="latest-item__title"><a href="blog_details.html">{item.b_title}</a></h6><span className="latest-item__date">{item.b_created_at}</span>
                </div>
                </div>
        ));

        const CommentsTemplate = comments.map((item,index)=>(
            <div key={index} className="comments__item">
                <div className="comments__item-img"><img className="img--bg" src={img} alt="img" /></div>
                <div className="comments__item-description">
                <div className="row align-items-center">
                    <div className="col-6"><span className="comments__item-name">{item.c_name}</span></div>
                    <div className="col-12">
                    <div className="comments__item-text">
                        <p dangerouslySetInnerHTML={{__html:item.c_content}}></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        ));

        const archiveTemplate = archive.map((item,index)=>{
          if(item.num < 1){
            return null;
          }
          return(
        <li className="category-list__item"><Link className="category-list__link" to={"/archive/"+(index+1)}><span>{item.month}</span><span>{item.num}</span></Link></li>
        )});
        return ( 
            <div>
            <Navbar />
            <Cover title={article.b_title}/>
            <section className="section blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-12"><img className="blog-post__img" src={article.img} alt="img" />
                  <h4 className="blog-post__title">{article.b_title}</h4>
                  <div dangerouslySetInnerHTML={{__html:article.b_content}}></div>
                </div>
              </div>
              <div className="row top-20">
                <div className="col-6">
                  <div className="blog-post__date">{article.b_created_at}</div>
                </div>
                <div className="col-6 text-right"><a className="blog-post__comments-count" href="#">{comments_num} {t('comments')}</a></div>
              </div>
              <div className="row top-50">
                <div className="col-12">
                  <div className="blog-post__author">
                    <div className="row align-items-center">
                      <div className="col-md-3 text-center text-md-left"><strong>By: {article.b_writer}</strong></div>
                      <div className="col-md-9 text-center text-md-right">
                        <ul className="socials socials--blue list--reset">
                          <li className="socials__item"><a className="socials__link" href="#">
                              <svg className="icon">
                                <use xlinkHref="#facebook" />
                              </svg></a></li>
                          <li className="socials__item"><a className="socials__link" href="#">
                              <svg className="icon">
                                <use xlinkHref="#twitter" />
                              </svg></a></li>
                          <li className="socials__item"><a className="socials__link" href="#">
                              <svg className="icon">
                                <use xlinkHref="#linkedin" />
                              </svg></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row top-60">
                <div className="col-12">
                  <div className="comments">
                    <h5 className="comments__title">{t('comments')}</h5>
                    
                    {CommentsTemplate}
                   
                  </div>
                </div>
              </div>
              <div className="row top-50">
                <div className="col-12">
                  <h5 className="blog-post__subtitle">{t('send')}</h5>
                  {this.state.loading?'':<Formik initialValues={{
                    name:"",
                    phone:"",
                    company:"",
                    email:"",
                    comment:"",
                    id:article.b_master,
                  }}
                  onSubmit={this.handleSubmit}>
                    <Form className="review-form" action="javascript:void(0);">
                      <div className="row">
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="name" placeholder={t('name')} />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="phone" placeholder={t('mobile')} />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="company" placeholder={t('company')} />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="email" name="email" placeholder={t('email')} />
                        </div>
                        <div className="col-12">
                          <Field className="form__field form__message message--large" as="textarea" name="comment" placeholder={t('notes')} defaultValue={""} />
                        </div>
                        <div className="col-12">
                          <button className="button button--green" type="submit"><span>{t('send')}</span> 
                            <svg className="icon">
                              <use xlinkHref="#arrow" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </Form>

                  </Formik>}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 top-70 top-lg-0">
              <div className="row">
                <div className="col-md-6 col-lg-12 bottom-50">
                  <h5 className="blog__title">{t('latest')}</h5>
                  
                  {LatestTemplate}
                 
                </div>
                <div className="col-md-6 col-lg-12 bottom-50">
                  <h5 className="blog__title">{t('archive')}</h5>
                  <ul className="category-list list--reset">
                    {archiveTemplate}
                  </ul>
                </div>
                <div className="col-12">
                  <ContactBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

            </div>
         );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang :state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Article));