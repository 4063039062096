import React, { Component } from 'react'
import Blog from './Blog';
import Companies from './Companies';
import Company from './Company';
import Contact from './Contact';
import Services from './Services';
import Slider from './Slider';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <main className="main">
            <Slider/>
            <Company />
            <Services/>
            <Blog />
            <Companies />
            <Contact />
        </main> );
    }
}
 
export default Header;