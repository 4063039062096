import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Navbar from '../Home/Components/Navbar';
import img from '../../assets/img/shop_1.jpg';
import Footer from '../Home/Components/Footer';
import ContactBox from '../../Components/ContactBox';
import {get,post} from '../../Api';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import qs from 'qs';
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = { cats:[],products:[],cat:{},loading:true ,search:[]}
    }
     async componentDidMount(){
        const {lang} = this.props;
        let id = this.props.match.params.id;
        let cats = await get('cats/' + lang);
        cats = cats.data.data;
        let parent = await get('cat_products/' + lang +'/'+id);
        let products = parent.data.data;
        console.log(products);
        let cat = parent.data.cat;
          this.setState({ cats,products,cat,loading:false  });
      }
      async componentDidUpdate(prevProps){
        const {lang} = this.props;
        let id = this.props.match.params.id;
        if(prevProps.lang != lang || prevProps.match.params.id != id){
          let cats = await get('cats/' + lang);
          cats = cats.data.data;
          let parent = await get('cat_products/' + lang +'/'+id);
          let products = parent.data.data;
          let cat = parent.data.cat;
            this.setState({cats, products,cat,loading:false  });

        }
    }

    change = (e) =>{
      let value = e.target.value
      if(value != ''){
        post('search',qs.stringify({search:value})).then(res => {
        let data = res.data;
        if(data.status){
          const search = data.search;
          this.setState({ search });
          console.log(search)
        }
      });
      }else{
        this.setState({ search:[]  });
      }
    }
    render() { 
        const {t} = this.props;
        const productTemplate = this.state.products.map((item,index)=>(
          <div key={index} className="col-sm-6 col-xl-4">
            <div className="shop-item text-center">
              <div className="shop-item__img"><img className="img--contain" src={item.img} alt="img" /></div>
              <h6 className="shop-item__title"><Link to={"/product/"+item.p_master}>{item.p_title}</Link></h6>
            </div>
          </div>
        ));
        const searchTemplate = this.state.search.map((item,index)=>(
          <Link to={"/product/"+item.p_master}>{item.p_title}</Link>
        ));
        const catsTemplate = this.state.cats.map((item,index)=>(
          <li key={index} className="category-list__item"> <Link className="category-list__link" to={"/cat/"+item.c_master}><span>{item.c_title}</span></Link></li>
        ));
        return ( 
            <div>
                <Navbar />
                <Cover title={this.state.loading?' Loading... ':this.state.cat.c_title} />
                <section className="section catalog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row offset-30">
                
                {this.state.loading?'loading':productTemplate}
              </div>
           
            </div>
            <div className="col-lg-4 col-xl-3">
              <div className="aside-holder">
                <div className="shop__aside-close">
                  <svg className="icon">
                    <use xlinkHref="#close" />
                  </svg>
                </div>
                <div className="shop-aside">
                  <div className="row">
                    <div className="col-lg-12 bottom-20">
                      <form className="form search-form" action="javascript:void(0);">
                        <input className="form__field" type="search" onChange={this.change} placeholder={t('search')+"..."} />
                        <button className="form__submit" type="submit">
                          <svg className="icon">
                            <use xlinkHref="#search" />
                          </svg>
                        </button>
                        <div className="result">
                          {searchTemplate}
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-12 bottom-50">
                      <h5 className="catalog__title">{t('cat')}</h5>
                      <ul className="category-list list--reset">
                        {catsTemplate}
                      </ul>
                    </div>
                    <ContactBox />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
                <Footer />

            </div>
         );
    }
}

const mapStateToProps = state =>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(Products));