import React, { Component } from 'react'
import img from '../../../assets/img/map_markers.png';
import {get} from '../../../Api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { settings:{} }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let settings = await get('settings/' + lang);
        settings = settings.data.data;
        this.setState({ settings,loading:false  });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let settings = await get('settings/' + lang);
            settings = settings.data.data;
            this.setState({ settings,loading:false  });

        }
    }
    render() { 
      const {settings} = this.state;
      const {t} = this.props;
      console.log('sett',settings);
        return ( 
    <section className="section front-contacts bg--dgray color--white"><img className="section--bg t50 r0" src={img} alt="bg" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="heading heading--white bottom-30">
        <h3 className="heading__title" dangerouslySetInnerHTML={{__html:t('if')}}></h3><span className="heading__layout layout--dgray">{t('contact')}</span>
              </div>
              <p className="bottom-40" dangerouslySetInnerHTML={{__html:settings.s_mission}}></p><Link className="button button--white" to="/contact"><span>{t('contact')}</span> 
                <svg className="icon">
                  <use xlinkHref="#arrow" />
                </svg></Link>
            </div>
            <div className="col-lg-5 col-xl-4 offset-xl-1 top-50 top-lg-0">
              <div className="row offset-30">
                <div className="col-md-6 col-lg-12">
                  <div className="office-item">
                    <h6 className="office-item__title">{t('b1')}</h6>
                    <div className="office-item__details">
                      <p><strong>{t('location')}:</strong> <span>{settings.address}</span></p>
                      <p><strong>{t('phone')}:</strong> <span>{settings.phone}</span> </p>
                      <p><strong>{t('email')}:</strong> <a href={"mailto:"+settings.email}>{settings.email}</a></p>
                      <p><strong>{t('open')}:</strong> <span>{settings.open}</span></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-12">
                  <div className="office-item">
                    <h6 className="office-item__title">{t('b2')}
</h6>
                    <div className="office-item__details">
                      <p><strong>{t('location')}:</strong> <span>{settings.address2}</span></p>
                      <p><strong>{t('phone')}:</strong> <span>{settings.phone2}</span> </p>
                      <p><strong>{t('email')}:</strong> <a href={"mailto:"+settings.email2}>{settings.email2}</a></p>
                      <p><strong>{t('open')}:</strong> <span>{settings.open2}</span></p>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
    </section>
         );
    }
}
 
const mapSTateToProps = state =>{
  return {
    lang:state.lang
  }
}
export default connect(mapSTateToProps)(withTranslation()(Contact));