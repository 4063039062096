import React, { Component } from 'react'
import ContactBox from '../../Components/ContactBox';
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],covers:{} }
    }
    async componentDidMount (){
        const {lang} = this.props;
        let data = await get('faq/'+lang);
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data,covers  });
    }
    async componentDidUpdate (preProps){
        const {lang} = this.props;
        if(preProps.lang != lang){
            let data = await get('faq/'+lang);
            data = data.data.data;
            this.setState({ data  });
        }
    }
    render() { 
        const template = this.state.data.map((item,index)=>(
            <AccordionItem title={item.f_q}>
              <p dangerouslySetInnerHTML={{__html:item.f_a}}></p>
            </AccordionItem>
                
        ));
        const {t} = this.props;
        return ( <div>
            <Navbar />
            <Cover title={t('faq')} cover={this.state.covers.c_img7} />
            <section className="section faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <h3 className="faq__title">{t('faq')}</h3>
                  <Accordion atomic={true}>
                
                      
                    {template}
                
                    </Accordion>
                  
                </div>
 
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 offset-xl-1">
              <ContactBox />
            </div>
          </div>
        </div>
      </section>

            <Footer />
        </div> );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Faq));