import {BrowserRouter as Router,Route} from 'react-router-dom';
import About from './Pages/About/Index';
import Factory from './Pages/Factory/Index';
import Home from './Pages/Home/Index';
import Page from './Pages/Page/Index';
import Product from './Pages/Product/Index';
import Products from './Pages/Products/Index';
import Photos from './Pages/Photos/index';
import Videos from './Pages/Videos/index';
import Blog from './Pages/Blog/index';
import {Provider} from 'react-redux';
import Store from './Store/Store';
import { useTranslation } from 'react-i18next';
import './assets/css/styles.min.css';
import Article from './Pages/Article';
import Downloads from './Pages/Downloads';
import Faq from './Pages/Faq';
import Careers from './Pages/Careers';
import Partners from './Pages/Partners';
import Contact from './Pages/Contact';
import Services from './Pages/Services';

function App() {
  const { i18n ,t} = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <Provider store={Store}>
        <Router>
          <div>
            <Route path="/" exact>
              <Home/>
            </Route>
            <Route path="/about" exact>
              <About/>
            </Route>
            <Route path="/factory" exact>
              <Factory />
            </Route>
            <Route path="/cat/:id" exact component={Products}/>
            <Route path="/product/:id" exact component={Product}/>
            <Route path="/photos" exact component={Photos}/>
            <Route path="/videos" exact component={Videos}/>
            <Route path="/blog" exact component={Blog}/>
            <Route path="/article/:id" exact component={Article}/>
            <Route path="/Downloads" exact component={Downloads}/>
            <Route path="/Faq" exact component={Faq}/>
            <Route path="/Careers" exact component={Careers}/>
            <Route path="/Partners" exact component={Partners}/>
            <Route path="/contact" exact component={Contact}/>
            <Route path="/page/:id" exact component={Page}/>
            <Route path="/services" exact component={Services}/>
          </div>
        </Router>
    </Provider>
  );
}

export default App;
