import React, { Component } from 'react'
import img from '../../../assets/img/video-block.jpg';
import ModalVideo from 'react-modal-video'
import '../../../../node_modules/react-modal-video/css/modal-video.min.css';
import {get} from '../../../Api';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = { open:false ,data:{},covers:{}}
    }

    async componentDidMount(){
        let data = await get('settings/en');
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data,covers  });
    }
    
    render() { 
        const {data,covers} = this.state;
        return ( 
               <>
            <ModalVideo channel='youtube' autoplay isOpen={this.state.open} videoId={data.video_id} onClose={() => this.setState({ open:false  })} />
             <section className="section video-section pt-0 pb-0">

                <div className="container">
                <div className="row">
                    <div className="col-12"><a className="js-video video-block"  onClick={()=>this.setState({ open:true  })}><img className="img--bg" src={(covers.c_img11 != '')?covers.c_img11:img} alt="img" />
                        <div className="video-block__inner"><span  className="video-icon">
                            <svg className="icon">
                            <use xlinkHref="#play" />
                            </svg></span></div></a></div>
                </div>
                </div>
            </section>
               </>
         );
    }
}
 
export default Video;