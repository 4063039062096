import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api/index';
import { connect } from 'react-redux';
import ContactBox from '../../Components/ContactBox';
import { Link } from 'react-router-dom';
class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = { blogs:[],archives:[],loading:true,covers:{} }
    }
    async componentDidMount(){
        const {lang,i18n} = this.props;
        let data = await get('blogs/'+lang);
        let blogs = data.data.data;
        let archive = data.data.archive;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ blogs,archive,loading:false ,covers });
    }
    async componentDidUpdate(prevProps){
        if(prevProps.lang != this.props.lang){
            const {lang} = this.props;
            let data = await get('blogs/'+lang);
            let blogs = data.data.data;
            let archive = data.data.archive;
            this.setState({ blogs,archive,loading:false  });

        }
    }
    render() { 
        const {blogs,archive,loading} = this.state;
        let archiveTemplate='';
        let blogTemplate='';
        if(!loading){
          archiveTemplate = archive.map((item,index)=>{
            if(item.num < 1){
              return null
            }  
          return(
            <li className="category-list__item"><a className="category-list__link" href="#"><span>{item.month}</span><span>{item.num}</span></a></li>
          )
        });
          blogTemplate = blogs.map((item,index)=>(
            <div className="col-md-6 col-xl-6">
              <div className="blog-item">
                <div className="blog-item__img"><img className="img--bg" src={item.img} alt="img" /></div>
                <h6 className="blog-item__title"> <Link to={'/article/'+item.b_master}>{item.b_title}</Link></h6>
                <div className="blog-item__text">{item.b_content}..</div>
                <div className="blog-item__details"><span>{item.b_created_at}</span></div>
              </div>
            </div>
          ));

        }
        return ( <div>
            <Navbar />
            <Cover title="Blog" cover={this.state.covers.c_img5}/>
           <section className="section blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row">
                
                {blogTemplate}
               
              </div>
             
            </div>
            <div className="col-lg-4 col-xl-3 top-70 top-lg-0">
              <div className="row">
               
                <div className="col-md-6 col-lg-12 bottom-50">
                  <h5 className="blog__title">Archive</h5>
                  <ul className="category-list list--reset">
                    {archiveTemplate}
                  </ul>
                </div>
                <div className="col-12">
                  <ContactBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
            <Footer />
        </div> );
    }
}
 
const mapStateToProps = state =>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps)(Blog);