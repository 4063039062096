const state ={
    lang:'en'
}
function Reducers(state,action) {
    switch (action.type) {
        case 'CHANGE_LANG':
            return {
                lang:action.lang
            }
            
    
        default:
            return state
    }
}

export default Reducers;