import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get} from '../../Api';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],covers:{} }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let data = await get('/services/'+lang); 
        let covers = await get('covers');
        covers = covers.data.data;
        data = data.data.data;
        this.setState({ data ,covers });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let data = await get('/services/'+lang); 
            data = data.data.data;
            this.setState({ data  });
            
        }
    }
    render() { 
        const {t} = this.props;
        const {data} = this.state;
        const Template = data.map((item,index)=>{
            if (index % 2 == 0) {
                return(
                    <section className="section">
                        <div className="container">
                        <div className="row flex-column-reverse flex-xl-row align-items-end">
                            <div className="col-xl-6 top-50 top-xl-0">
                            <div className="heading bottom-20">
                                <h3 className="heading__title">{item.s_title}</h3>
                            </div>
                            <p className="bottom-0">{item.s_content}</p><br /><br />
                            </div>
                            <div className="col-xl-6 text-center text-xl-left"><img className="truck-img" src={item.img} alt="img" /></div>
                        </div>
                        </div>
                    </section>
                )
            } else {
                    return(
                        <section className="section">
                            <div className="container">
                            <div className="row flex-column-reverse flex-xl-row align-items-end">
                                <div className="col-xl-6 text-center text-xl-left"><img className="truck-img" src={item.img} alt="img" /></div>
                                <div className="col-xl-6 top-50 top-xl-0">
                                <div className="heading bottom-20">
                                    <h3 className="heading__title">{item.s_title}</h3>
                                </div>
                                <p className="bottom-0">{item.s_content}</p><br /><br />
                                </div>
                            </div>
                            </div>
                        </section>
                    )
                
            }
        }
        );
        return ( 
         <>
         <Navbar />
         <Cover title={t('services')} cover={this.state.covers.c_img2}/>
         {Template}    
         <Footer />
         </>
         );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Services));

