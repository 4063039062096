import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Contact from '../Home/Components/Contact';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import img from '../../assets/img/page.jpg'
import {get} from '../../Api';
import { connect } from 'react-redux';
class Page extends Component {
    constructor(props) {
        super(props);
        this.state = { data:{} }
    }
    async componentDidMount(){
      const {lang} = this.props;
      const id = this.props.match.params.id;
      let data = await get('page/'+id+'/'+lang);
      data = data.data.data;
      this.setState({ data });
    }
    async componentDidUpdate(preProps){
      console.log('lang',preProps.lang);
      if(preProps.lang != this.props.lang || preProps.match.params.id != this.props.match.params.id){
        const {lang} = this.props;
        const id = this.props.match.params.id;
        let data = await get('page/' + id + '/' + lang);
        data = data.data.data;
        this.setState({
          data
        });
      }
    }
    render() { 
      const {data} = this.state;
    
        return ( 
            <>
            <Navbar />
            <Cover title={data.p_title} cover={img}/>
            <div dangerouslySetInnerHTML={{__html:data.p_html}}>
              
            </div>
            {/* <style>
              {data.p_css}
            </style> */}
            <Contact />
            <Footer />
            </>
         );
    }
}
 
const mapStateToProps = state =>{
  return {
    lang:state.lang
  }
}
export default connect(mapStateToProps)(Page);