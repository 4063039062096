import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import img from '../../../assets/img/warehouse_img.jpg';
import img2 from '../../../assets/img/slider_img.jpg';
import ContactBox from '../../../Components/ContactBox';
class FactoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
    <section className="section warehouse">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row bottom-20">
                <div className="col-12"><img className="warehouse__img" src={img} alt="img" />
                  <h4 className="warehouse__title">El-Obour City</h4>
                  <p>El-Tawheed Industrial Group for Metal, Wire, and Springs is the one of the Largest and Leading manufacturer in Egypt, Africa, MENA &amp; GCC for Metal forming with 2 manufacturing facilities/locations located through Egypt.El-Tawheed Industrial Group for Metal, Wire, and Springs is the one of the Largest and Leading manufacturer in Egypt, Africa, MENA &amp; GCC for Metal forming with 2 manufacturing facilities/locations located through Egypt.</p>
                  <p>El-Tawheed Industrial Group for Metal, Wire, and Springs is the one of the Largest and Leading manufacturer in Egypt, Africa, MENA &amp; GCC for Metal forming with 2 manufacturing facilities/locations located through Egypt.El-Tawheed Industrial Group for Metal, Wire, and Springs is the one of the Largest and Leading manufacturer in Egypt, Africa, MENA &amp; GCC for Metal forming with 2 manufacturing facilities/locations located through Egypt.</p>
                </div>
              </div>
              <div className="row offset-20 top-60">
                <div className="col-12">
                  <div className="images-slider">
                    <OwlCarousel>
                      <a className="images-slider__item" href={img2} data-fancybox="gallery-2"><img className="img--bg" src={img2} alt="img" /></a>
                      <a className="images-slider__item" href={img2} data-fancybox="gallery-2"><img className="img--bg" src={img2} alt="img" /></a>
                      <a className="images-slider__item" href={img2} data-fancybox="gallery-2"><img className="img--bg" src={img2} alt="img" /></a>

                    </OwlCarousel>
                  </div>
                  <div className="images-slider__nav" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3 top-70 top-lg-0">
              <div className="row">
                <div className="col-12 bottom-60">
                  <h5 className="warehouse__subtitle">FactoryItem information</h5>
                  <div className="row offset-30">
                    <div className="col-sm-6 col-md-4 col-lg-12">
                      <div className="info-item">
                        <div className="info-item__img">
                          <svg className="icon">
                            <use xlinkHref="#shape" />
                          </svg>
                        </div>
                        <div className="info-item__details"><strong>154, 900 sqft</strong><span>Size</span></div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-12">
                      <div className="info-item">
                        <div className="info-item__img">
                          <svg className="icon">
                            <use xlinkHref="#calendar" />
                          </svg>
                        </div>
                        <div className="info-item__details"><strong>2015</strong><span>Year built</span></div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-12">
                      <div className="info-item">
                        <div className="info-item__img">
                          <svg className="icon">
                            <use xlinkHref="#cctv" />
                          </svg>
                        </div>
                        <div className="info-item__details"><strong>A-Trap compliant</strong><span>Security</span></div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-12">
                      <div className="info-item">
                        <div className="info-item__img">
                          <svg className="icon">
                            <use xlinkHref="#clock" />
                          </svg>
                        </div>
                        <div className="info-item__details"><strong>5 am - 10:00 PM</strong><span>Hours of opening</span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <ContactBox />
              </div>
            </div>
          </div>
        </div>
    </section>
         );
    }
}
 
export default FactoryItem; 