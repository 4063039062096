import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import img from '../../../assets/img/logo_white2.png';
import {get,post} from '../../../Api';
import { Link } from 'react-router-dom';
import {Formik ,Form,Field} from 'formik';
import qs from 'qs'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = { data:{} }
    }
    async componentDidMount (){
      const {lang} = this.props;
      let data = await get('settings/'+lang);
      data = data.data.data;
      this.setState({ data });
    }
    async componentDidUpdate (prevProps){
      const {lang} = this.props;
      if(prevProps.lang != lang){
        let data = await get('settings/'+lang);
        data = data.data.data;
        this.setState({ data });
      }
    }
    handleSubmit = (values,{resetForm}) =>{
      post('/news',qs.stringify(values)).then(res =>{
        const data = res.data;
        if(data.status){
          NotificationManager.success('Thannk You !');
          resetForm({})
        }else{
          NotificationManager.error(data.error);
        }
      });
    }
    render() { 
      const {data} = this.state;
      const {t} = this.props;

        return ( 
    <footer className="page-footer footer_3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="page-footer__logo"><a href="index.html"><img src={img} alt="logo" /></a></div>
              <div className="page-footer__details">
                <p dangerouslySetInnerHTML={{__html:data.s_short_about}}></p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 top-30 top-md-0">
              <h6 className="page-footer__title title--white">{t('discover')}</h6>
              <ul className="page-footer__menu list--reset">
                <li><Link to="/about">{t('about')}</Link></li>
                <li><Link to="/ceo">{t('ceo')}</Link></li>
                <li><Link to="/careers">{t('careers')}</Link></li>
                <li><Link to="/quality">{t('quality')}</Link></li>
                <li><Link to="/blog">{t('blog')}</Link></li>
                <li><Link to="/contact">{t('contact')}</Link></li>
                <li><Link to="/services">{t('services')}</Link></li>
                <li><Link to="/partners">{t('partners')}</Link></li>
                <li><Link to="/smap">{t('smap')}</Link></li>
                <li><Link to="/photos">{t('photo')}</Link></li>
                <li><Link to="/videos">{t('video')}</Link></li>
                <li><Link to="/faq">{t('faq')}</Link></li>
              </ul>
            </div>
            <div className="col-lg-4 top-30 top-lg-0">
              <h6 className="page-footer__title title--white">{t('newsletter')}</h6>
              <Formik initialValues={{email:''}} onSubmit={this.handleSubmit}>
                  <Form className="form newslatter-form">
                    <div className="fieldset">
                      <Field className="form__field" type="email" name="email" placeholder={t('email')} />
                      <button className="form__submit" type="submit">
                        <svg className="icon">
                          <use xlinkHref="#mail" />
                        </svg>
                      </button>
                    </div>
                  </Form>
              </Formik>
              
            </div>
          </div>
          <div className="row top-50 flex-column-reverse flex-sm-row">
            <div className="col-sm-6 col-lg-4 text-center text-md-left top-20 top-sm-0">
              <div className="page-footer__copyright">© 2020 Newvision-it.com. All rights reserved</div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="page-footer__privacy"><a href="#">Terms and conditions</a><a href="#">Privacy policy</a><a href="#">Cookies</a></div>
            </div>
            <div className="col-sm-6 col-lg-3 text-center text-md-right">
              <ul className="socials list--reset">
               {data.youtube != ''?<li className="socials__item"><a className="socials__link" href={data.youtube}>
                            <svg className="icon">
                            <use xlinkHref="#youtube" />
                            </svg></a></li>:null}
                        {data.facebook != ''?<li className="socials__item"><a className="socials__link" href={data.facebook}>
                            <svg className="icon">
                            <use xlinkHref="#facebook" />
                            </svg></a></li>:null}
                        {data.twitter != ''?<li className="socials__item"><a className="socials__link" href={data.twitter}>
                            <svg className="icon">
                            <use xlinkHref="#twitter" />
                            </svg></a></li>:null}
                        {data.linkedin != ''?<li className="socials__item"><a className="socials__link" href={data.linkedin}>
                            <svg className="icon">
                            <use xlinkHref="#linkedin" />
                            </svg></a></li>:null}
              </ul>
            </div>
          </div>
        </div>
        <NotificationContainer/>
    </footer>
         );
    }
}
 
const mapStateToProps = state =>{
  return {
    lang:state.lang
  }
}
export default connect(mapStateToProps)(withTranslation()(Footer));