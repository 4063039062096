import React, { Component } from 'react'
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import img from '../../assets/img/map_back.png';
import Company from '../Home/Components/Company';
import Video from './Components/AboutVideo';
import TimeLine from './Components/TimeLine';
import Contact from '../Home/Components/Contact';
import { withTranslation } from 'react-i18next';
import {get} from '../../Api';
class About extends Component {
    constructor(props) {
        super(props);
        this.state = { data:{} }
    }
    async componentDidMount(){
        let data = await get('covers');
        data = data.data.data;
        this.setState({ data:data  });
        console.log(this.state.data);
    }
    render() { 
        return ( 
            <div>
                <Navbar />
                <Cover cover={this.state.data.c_img1} title={this.props.t('about')}/>
                <Company about={true}/>
                <Video />
                <TimeLine />
                <Contact />
                <Footer />
            </div>
         );
    }
}
 
export default withTranslation()(About);