import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import img3 from '../assets/img/contact_background.png';

class ContactBox extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {t} = this.props;
        return ( 
            <div className="col-12">
                <div className="contact-trigger contact-trigger--style-2"><img className="contact-trigger__img" src={img3} alt="img" />
                    <h4 className="contact-trigger__title">{t('how')}</h4>
                    <p className="contact-trigger__text">{t('can')}</p><a className="button button--white" href="#"><span>{t('contact')}</span> 
                    <svg className="icon">
                        <use xlinkHref="#arrow" />
                    </svg></a>
                </div>
            </div>
         );
    }
}
 
export default withTranslation()(ContactBox);