import React, { Component } from 'react'
import Footer from './Components/Footer';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
import Slider from './Components/Slider';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <div>
            <Navbar/>
            <Header />
            {/* <Slider/> TODO - problem in the slider  */}
            <Footer />

        </div> );
    }
}
 
export default Home;