import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         }
    }
    
    render() { 
        const {item,products} = this.props;
        const {parent,children} = item;
        const productsTemp = products.map((item,index)=>(
             <li key={index}><Link to={'/cat/'+item.c_master}><span>{item.c_title}</span></Link></li>
        ));
        if(children.length > 0){
            const childTemp = children.map((item,index)=>{
                if(item.parent.m_type == 3){
                    return(
                        <li key={index}><Link to={"/page"+item.parent.m_link}><span>{item.parent.m_title}</span></Link></li>
                    )
                }else{
                    return(
                        <li key={index}><Link to={item.parent.m_link}><span>{item.parent.m_title}</span></Link></li>
                    )
                }
            });
            return (
                <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>{parent.m_title}</span></a>
                <ul className="main-menu__sub-list">
                    {childTemp}
                    
                </ul>
            </li>
            )
        }else{
            if(parent.m_type <= 1){
                
                if(parent.m_parent_for == 4){
                    return (
                        <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>{parent.m_title}</span></a>
                        <ul className="main-menu__sub-list">
                            {productsTemp}
                            
                        </ul>
                    </li>
                    )
                }else{
                    return ( 
                    <li className="main-menu__item"><Link className="main-menu__link" to={parent.m_link}><span>{parent.m_title}</span></Link></li>
                );
                }
            }else if(parent.m_type == 2){
                return ( 
                    <li className="main-menu__item"><Link className="main-menu__link" to={"/page"+parent.m_link}><span>{parent.m_title}</span> </Link></li>
                );

            }
            
        }
    }
}
 
export default Menu;