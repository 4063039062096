import {createStore} from 'redux';
import Reducers from './Redusers/Index';
import throttle from 'lodash.throttle';

function loadState() {
    try {
        const state = localStorage.getItem('state');
        if (state !== null) {
            console.log(JSON.parse(state));
            return JSON.parse(state);
        }
    } catch (e) {

    }
    return {
        lang: 'en'
    }
}

function saveState(state) {
    localStorage.setItem('state', JSON.stringify(state));
}
const store = createStore(Reducers, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

store.subscribe(throttle(() => {
    saveState(store.getState());
}), 2000)
export default store;