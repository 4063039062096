import React, { Component } from 'react'
import img from '../../../assets/img/gbr.png';
import logo from '../../../assets/img/logo_white.png';
import logo2 from '../../../assets/img/logo_black.png';
import {get} from '../../../Api';
import { connect } from 'react-redux';
import Menu from './Menu';
import {changeLang} from '../../../Store/Actions/Actions';
import {withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            products:[],
            settings:{},
            loading:false,
            stylepath: "/assets/css/style-rtl.css"

         }
    }
    async componentDidMount(){
        const {lang,i18n} = this.props;
        i18n.changeLanguage(this.props.lang);
        let data = await get('menu/'+lang);
        data = data.data;

        let products = await get('cats/'+lang);
        products = products.data.data;

        let settings = await get('settings/'+lang);
        settings = settings.data.data;
        this.setState({ settings,data,products,loading:false  });
    }
    async componentDidUpdate(prevProps){
        if(prevProps.lang != this.props.lang){
            const {lang} = this.props;
            let data = await get('menu/'+lang);
            data = data.data;
            let products = await get('products/'+lang);
            products = products.data.data;
            let settings = await get('settings/' + lang);
            settings = settings.data.data;
            this.setState({
                settings,
                data,
                products,
                loading: false
            });

        }
    }
    changeLanguage = lng => {
        const { i18n } = this.props;
        i18n.changeLanguage(lng);
        this.props.changeLang(lng);
    };
    render() { 
        const {loading,data,settings} = this.state;
        const MenuTemplate = data.map((item,index)=>(
            <Menu item={item} products={this.state.products}/>
        ));
        return ( 
        <div>
            {(this.props.lang && this.props.lang === 'ar')?<link rel="stylesheet" href={this.state.stylepath}/>:""}
            <div className="menu-dropdown">
            <div className="menu-dropdown__inner" data-value="start">
                <div className="screen screen--start">
                <div className="menu-dropdown__close">
                    <svg className="icon">
                    <use xlinkHref="#close" />
                    </svg>
                </div>
                <div className="d-block d-lg-none bottom-20">
                    <div className="screen__item screen--trigger item--active" data-category="screen-one"><span>Home</span><span>
                    </span></div>
                    <div className="screen__item screen--trigger" data-category="screen-two"><span>Overview</span><span>
                        <svg className="icon">
                        <use xlinkHref="#chevron-right" />
                        </svg></span></div>
                    <div className="screen__item screen--trigger" data-category="screen-five"><span>Products</span><span>
                        <svg className="icon">
                        <use xlinkHref="#chevron-right" />
                        </svg></span></div>
                </div>
                <div className="screen__item"><a className="screen__link" href="#">Services</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Factory</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Quality</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Partners</a></div>
                <div className="screen__item screen--trigger" data-category="screen-three"><span>Media</span><span>
                    <svg className="icon">
                        <use xlinkHref="#chevron-right" />
                    </svg></span></div>
                <div className="screen__item"><a className="screen__link" href="#">Blog</a></div>
                <div className="screen__item"><a className="screen__link" href="#">FAQ’s</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Careers</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Downloads</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Reach Us</a></div>
                <div className="screen__item screen--trigger d-flex d-lg-none" data-category="screen-six"><span>Language</span><span>
                    <svg className="icon">
                        <use xlinkHref="#chevron-right" />
                    </svg></span></div>
                <div className="menu-dropdown__block top-50"><span className="block__title">Email</span><a className="screen__link" href="mailto:{settings.email}">{settings.email}</a></div>
                <div className="menu-dropdown__block top-20"><span className="block__title">Phone numbers</span><a className="screen__link" href="tel:+202 25789622">+202 25789622</a><a className="screen__link" href="tel:01000282228">	
                    +2 01000282228</a></div>	
                <div className="menu-dropdown__block">
                    <ul className="socials list--reset">
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                            <use xlinkHref="#youtube" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                            <use xlinkHref="#facebook" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                            <use xlinkHref="#twitter" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                            <use xlinkHref="#linkedin" />
                        </svg></a></li>
                    <li className="socials__item"><a className="socials__link" href="#">
                        <svg className="icon">
                            <use xlinkHref="#inst" />
                        </svg></a></li>
                    </ul>
                </div>
                <div className="menu-dropdown__block top-50"><a className="button button--filled" href="#">Get a Quote</a>
                </div>
                </div>
            </div>
            <div className="menu-dropdown__inner" data-value="screen-two">
                <div className="screen screen--sub">
                <div className="screen__heading">
                    <h6 className="screen__back">
                    <svg className="icon">
                        <use xlinkHref="#chevron-left" />
                    </svg> <span>Overview</span>
                    </h6>
                </div>
                <div className="screen__item"><a className="screen__link" href="#">Who We Are</a></div>
                <div className="screen__item"><a className="screen__link" href="#">CEO - Director Manager</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Our Vision</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Our Mission</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Meet Our Team</a></div>
                </div>
            </div>
            <div className="menu-dropdown__inner" data-value="screen-three">
                <div className="screen screen--sub">
                <div className="screen__heading">
                    <h6 className="screen__back">
                    <svg className="icon">
                        <use xlinkHref="#chevron-left" />
                    </svg> <span>Media</span>
                    </h6>
                </div>
                <div className="screen__item"><a className="screen__link" href="#">Photo Gallery</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Video Gallery</a></div>
                </div>
            </div>
            <div className="menu-dropdown__inner" data-value="screen-five">
                <div className="screen screen--sub">
                <div className="screen__heading">
                    <h6 className="screen__back">
                    <svg className="icon">
                        <use xlinkHref="#chevron-left" />
                    </svg> <span>Products</span>
                    </h6>
                </div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Category Name</a></div>
                </div>
            </div>
            <div className="menu-dropdown__inner" data-value="screen-six">
                <div className="screen screen--sub">
                <div className="screen__heading">
                    <h6 className="screen__back">
                    <svg className="icon">
                        <use xlinkHref="#chevron-left" />
                    </svg> <span>Language</span>
                    </h6>
                </div>
                <div className="screen__item"><a className="screen__link" href="#">English</a></div>
                <div className="screen__item"><a className="screen__link" href="#">Arabic</a></div>
                </div>
            </div>
            </div>
            <header className="page-header_2">
            <div className="page-header__top d-none d-xl-block">
                <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-xl-8"><span>
                        <svg className="icon">
                        <use xlinkHref="#pin" />
                        </svg> 	{settings.fet_title3}</span><span>
                        <svg className="icon">
                        <use xlinkHref="#phone" />
                        </svg><a href={"tel:"+settings.fet_title4}>{settings.fet_title4}</a></span><span>
                        <svg className="icon">
                        <use xlinkHref="#mail" />
                        </svg><a href={"mailto:"+settings.email}>{settings.fet_content3}</a> </span></div>
                    <div className="col-xl-4 text-right">
                    <ul className="socials list--reset">
                        {settings.youtube != ''?<li className="socials__item"><a className="socials__link" href={settings.youtube}>
                            <svg className="icon">
                            <use xlinkHref="#youtube" />
                            </svg></a></li>:null}
                        {settings.facebook != ''?<li className="socials__item"><a className="socials__link" href={settings.facebook}>
                            <svg className="icon">
                            <use xlinkHref="#facebook" />
                            </svg></a></li>:null}
                        {settings.twitter != ''?<li className="socials__item"><a className="socials__link" href={settings.twitter}>
                            <svg className="icon">
                            <use xlinkHref="#twitter" />
                            </svg></a></li>:null}
                        {settings.linkedin != ''?<li className="socials__item"><a className="socials__link" href={settings.linkedin}>
                            <svg className="icon">
                            <use xlinkHref="#linkedin" />
                            </svg></a></li>:null}
 
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            <div className="page-header__lower">
                <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-8 col-md-6 col-lg-3 d-flex align-items-center">
                    <div className="page-header__logo logo--white"><Link to="/"><img src={logo} alt="eltawheed" /></Link></div>
                    <div className="page-header__logo logo--dark"><Link to="/"><img src={logo2} alt="eltawheed" /></Link></div>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                    <ul className="main-menu main-menu--white">
                        {loading?'Loading ...':MenuTemplate}
                        {/* <li className="main-menu__item main-menu__item--active"><a className="main-menu__link" href="#"><span>Home</span></a></li>
                        <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>Overview</span></a>
                        <ul className="main-menu__sub-list">
                            <li><a href="about_us.html"><span>Who We Are</span></a></li>
                            <li><a href="ceo.html"><span>CEO - Director Manager</span></a></li>
                            <li><a href="vision.html"><span>Our Vision</span></a></li>
                            <li><a href="mission.html"><span>Our Mission</span></a></li>
                            <li><a href="factory.html"><span>Factory</span></a></li>
                            <li><a href="services.html"><span>Services</span></a></li>
                            <li><a href="quality.html"><span>Quality</span></a></li>
                        </ul>
                        </li>
                        <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>Products</span></a>
                        <ul className="main-menu__sub-list">
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                            <li><a href="products_items.html"><span>Category Name</span></a></li>
                        </ul>
                        </li>
                        <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>Media</span></a>
                        <ul className="main-menu__sub-list">
                            <li><a href="photos.html"><span>Photo Gallery</span></a></li>
                            <li><a href="videos.html"><span>Video Gallery</span></a></li>
                        </ul>
                        </li>
                        <li className="main-menu__item main-menu__item--has-child"><a className="main-menu__link" href="#"><span>KNOWLEDGE</span></a>
                        <ul className="main-menu__sub-list">
                            <li><a href="blog.html"><span>Blog</span></a></li>
                            <li><a href="faq.html"><span>FAQ’s</span></a></li>
                            <li><a href="downloads.html"><span>Downloads</span></a></li>
                            <li><a href="careers.html"><span>Careers</span></a></li>
                        </ul>
                        </li>
                        <li className="main-menu__item main-menu__item"><a className="main-menu__link" href="partners.html"><span>Partners</span></a></li>
                        <li className="main-menu__item main-menu__item"><a className="main-menu__link" href="contact_us.html"><span>Contacts</span></a></li> */}
                    </ul>
                    </div>
                    <div className="col-4 col-md-6 col-lg-3 d-flex justify-content-end align-items-center">
                    <div className="lang-block">
                        <div className="lang-icon"><img className="img--contain" src={img} alt="img" /></div>
                        <ul className="lang-select lang-select--white">
                        <li className="lang-select__item lang-select__item--active"><span>En</span>
                            <ul className="lang-select__sub-list">
                            <li><a href="#" onClick={()=>this.changeLanguage("en")}>English</a></li>
                            <li><a href="#" onClick={()=>this.changeLanguage("ar")}>Arabic</a></li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                    <div className="hamburger d-inline-block d-md-none hamburger--white">
                        <div className="hamburger-inner" />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </header>
        </div> );
    }
}
 
const mapStateToProps = (state)=>{
    return {
        lang:state.lang
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeLang:(lang)=>dispatch(changeLang(lang))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Navbar));