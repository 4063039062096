import React, { Component } from 'react'
import img from '../../../assets/img/map_back.png';
import {get} from '../../../Api';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Company  extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            settings:{}
         }
    }
    async componentDidMount(){
        const {lang} = this.props;
        let settings = await get('settings/' + lang);
        settings = settings.data.data;
        this.setState({ settings,loading:false  });
    }
    async componentDidUpdate(prevProps){
        const {lang} = this.props;
        if(prevProps.lang != lang){
            let settings = await get('settings/' + lang);
            settings = settings.data.data;
            this.setState({ settings,loading:false  });

        }
    }
    render() { 
        const {settings} = this.state;
        const {t,about} = this.props;
        return ( 
            <section className="section">
                <div className="container">
                    <div className="row flex-column-reverse flex-xl-row">
                        <div className="col-md-8 col-lg-7 col-xl-5 top-50 top-xl-0">
                        <div className="counters-holder text-center text-sm-left"><img className="img--bg" src={img} alt="img" />
                            <div className="row offset-50">
                            <div className="col-12">
                                <div className="counter counter--white">
                                <div className="counter__top"><span className="js-counter counter__count color--green">{settings.veh}</span><span className="counter__subject color--green">yrs</span></div>
                                <div className="counter__lower"><span className="counter__details color--lgray">{settings.s_counter1}</span></div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="counter counter--white">
                                <div className="counter__top"><span className="js-counter counter__count color--green">{settings.team}</span><span className="counter__subject color--green">+</span></div>
                                <div className="counter__lower"><span className="counter__details color--lgray">{settings.s_counter3}</span></div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="counter counter--white">
                                <div className="counter__top"><span className="js-counter counter__count color--green">{settings.customers}</span></div>
                                <div className="counter__lower"><span className="counter__details color--lgray">{settings.s_counter2}</span></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-6 offset-xl-1">
                        <div className="heading bottom-20"><span className="heading__pre-title">{t('about')}</span>
                            <h3 className="heading__title">{settings.s_fet_title1}</h3><span className="heading__layout">{t('about')}</span>
                        </div>
                        <p dangerouslySetInnerHTML={{__html:(!about)?settings.s_fet_content1:settings.s_fet_content2}}>
                        </p>
                      {!about?<Link className="button button--green" to="/about"><span>{t('more')}</span> 
                            <svg className="icon">
                            <use xlinkHref="#arrow" />
                            </svg></Link>:null}
                        </div>
                    </div>
                </div>
            </section>
         );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Company));