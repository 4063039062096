import React, { Component } from 'react'
import ContactBox from '../../Components/ContactBox';
import Cover from '../../Components/Cover';
import Footer from '../Home/Components/Footer';
import Navbar from '../Home/Components/Navbar';
import {get,posti} from '../../Api';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {Formik,Form,Field} from 'formik';
import qs from 'qs';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Accordion, AccordionItem } from 'react-light-accordion';

import 'react-notifications/lib/notifications.css';
class Careers extends Component {
    constructor(props) {
        super(props);
        this.state = { data:[],loading:true ,covers:{}}
    }
    async componentDidMount(){
        const {lang} = this.props;
        let data = await get('careers/'+lang);
        data = data.data.data;
        let covers = await get('covers');
        covers = covers.data.data;
        this.setState({ data,loading:false ,covers});

    }
    async componentDidUpdate(preProps){
        const {lang} = this.props;
        if(preProps.lang != lang){
            let data = await get('careers/' + lang);
            data = data.data.data;
            this.setState({
                data,loading:false
            });
        }

    }
     handleSubmit = (values,{resetForm})=>{
      console.log(values)
      var form_data = new FormData();
      for (var key in values) {
        form_data.append(key, values[key]);
      }
      posti('career', form_data).then(res => {
        const data = res.data;
        if (data.status) {
          NotificationManager.success('Thannk You !');
          resetForm({})
        } else {
          NotificationManager.error(data.error);
        }
      });
    }
    render() { 
        console.log(this.state.data,'data')
        const careersTemplate = this.state.data.map((item,map)=>(
          <AccordionItem title={item.c_title}>
              <p dangerouslySetInnerHTML={{__html:item.c_content}}></p>
            </AccordionItem>
          
        ));
        const {t} = this.props;
        return ( <div>
            <Navbar />
            <Cover title={t('careers')} cover={this.state.covers.c_img8} />
            <section className="section positions-archive">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="positions-archive__title">{t('careers')}</h3>
              
              {this.state.loading?'Loadnig ...':<Accordion atomic={true}>
                
                      
                    {careersTemplate}
                
                    </Accordion>}
              
             
            </div>
            <div className="col-lg-4 col-xl-3 offset-xl-1">
              <ContactBox />
            </div>
          </div>
        </div>
      </section>

      <section className="section blog bg--lgray">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row top-50">
                <div className="col-12">
                  <h5 className="blog-post__subtitle">{t('join')}</h5>
                  <Formik initialValues={{
                    name:'',
                    code:'',
                    phone:'',
                    email:'',
                    file:null,
                    msg:'',
                  }}
                  onSubmit={this.handleSubmit}>
                    {({values,setFieldValue}) =>(
                      <Form className="review-form" action="javascript:void(0);">
                      <div className="row">
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="name" placeholder={t('name')} />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="code" placeholder="Job Code*" />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="text" name="phone" placeholder={t('mobile')} />
                        </div>
                        <div className="col-md-6">
                          <Field className="form__field" type="email" name="email" placeholder={t('email')} />
                        </div>
                        <div className="col-md-12">
                          <label> C.V*</label>
                          <Field className="form__field" type="file" name="cv" onChange={(e)=>{setFieldValue('file',e.currentTarget.files[0]);console.log(values.file)}} placeholder="Upload your C.V*" style={{backgroundColor: '#fff'}} />
                        </div>
                        <div className="col-12">
                          <Field as="textarea" className="form__field form__message message--large" name="msg" placeholder={t('notes')} defaultValue={""} />
                        </div>
                        <div className="col-12">
                          <button className="button button--green" type="submit"><span>{t('send')}</span> 
                            <svg className="icon">
                              <use xlinkHref="#arrow" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </Form>
                    )}

                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
            <Footer />
        </div> );
    }
}
 
const mapStateToProps = state =>{
    return {
        lang:state.lang
    }
}
export default connect(mapStateToProps)(withTranslation()(Careers));